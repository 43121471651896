import { cn } from "utils/cn";
import Link from "next/link";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";

const renderContent = (name): React.ReactElement => {
  switch (name) {
    case "infoSectionHeader":
      return (
        <>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            General information about your activity.
          </p>
        </>
      );
    case "ticketsSectionHeader":
      return (
        <>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Tickets
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            The tickets available for the activity.
          </p>
        </>
      );
    case "activityIntancesHeader":
      return (
        <>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Sessions
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            The dates and times the activity will take place on.
          </p>
        </>
      );
    case "homePageSettingsFiltersHeader":
      return (
        <>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Filters
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            The activity fields you wish to be used as filters on the home page.
            You can add additional fields in the{" "}
            <Link href="/admin/settings/fields?usage=activity">
              <a className="font-medium text-indigo-600">Activity fields</a>
            </Link>{" "}
            settings.
          </p>
        </>
      );
    default:
      return (
        <>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            (title)
          </h3>

          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            (section content)
          </p>
        </>
      );
  }
};

interface SectionHeaderProps
  extends UseFieldApiProps<{ name: string }, HTMLElement> {
  title?: string;
  description?: string;
  index?: number;
  badgeText?: string;
  customClassName?: string;
}

const SectionHeader = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    title,
    badgeText,
    description,
    customClassName,
    index
  }: SectionHeaderProps = useFieldApi(props);
  return (
    <div className={cn(customClassName, index !== 0 && "mt-5 pt-8")}>
      {title ? (
        <>
          <div className="flex gap-3">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            {badgeText && (
              <span className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800">
                {badgeText}
              </span>
            )}
          </div>
          {description && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {description}
            </p>
          )}
        </>
      ) : (
        <>{renderContent(props.name)}</>
      )}
    </div>
  );
};

export default SectionHeader;
