import type { Schema } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import type { DiscountRuleRedemptionLimitsValue } from "components/form/field/DiscountRuleRedemptionLimits";
import type { StartEndDateValue } from "components/form/field/StartDateEndDate";
import { formatDate } from "helpers/date";
import { getDiscountRuleTypeReadableName } from "helpers/discount";
import { getCurrencySymbol } from "helpers/helpers";
import { FormTemplateType } from "types/form";
import type { ActivityGroupFullListItem } from "types/model/activity-group";
import type { Client } from "types/model/client";
import type {
  DiscountRule,
  MultiActivityCondition,
  MultiSessionCondition
} from "types/model/discount-rule";
import {
  DiscountCodeUsageType,
  DiscountRuleSessionsOption,
  DiscountRuleType,
  DiscountRuleUnit,
  MultiPurchaseDiscountRuleType
} from "types/model/discount-rule";
import type { Field as FieldModel } from "types/model/field";
import type { Venue } from "types/model/venue";
import { isBefore } from "date-fns";
import type { DiscountRuleFormData } from "types/model/discount-rule";
import type { FormErrors } from "types/model/form";
import { getCurrencyAmountForDisplay } from "helpers/currency";

const getUnitFieldName = (
  field: { component: string; name: string },
  fieldName: string
): string => {
  return field.name.replace(fieldName, "unit");
};

interface GenerateDiscountRuleFormSchemaData {
  discountRule?: DiscountRule;
  activityGroups?: ActivityGroupFullListItem[];
  venues?: Venue[];
  activityFields?: FieldModel[];
  client: Client;
  includeActivityGroupSelect?: boolean;
  formTemplate?: FormTemplateType;
  inModal?: boolean;
  prefix?: string;
  type?: DiscountRuleType;
}

export const generateDiscountRuleFormSchema = ({
  discountRule,
  activityGroups,
  venues,
  activityFields,
  client,
  includeActivityGroupSelect = true,
  formTemplate = FormTemplateType.Default,
  inModal = false,
  prefix = "",
  type
}: GenerateDiscountRuleFormSchemaData): Schema => {
  const prefixPath = prefix ? `${prefix}.` : "";
  const shouldDisableConfigFields = discountRule?.timesUsed > 0;

  const regex = new RegExp("^[a-zA-Z0-9]*$");
  const hasExistingCodeThatDoesNotMatchValidation =
    Boolean(discountRule?.code) && !regex.test(discountRule.code);

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefixPath}name`,
      label: "Name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: discountRule?.name,
      helpText: "Only used for admin purposes."
    },
    {
      index: 1,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: `${prefixPath}type`,
      label: "Type",
      options: [
        {
          value: DiscountRuleType.MultiPurchase,
          label: getDiscountRuleTypeReadableName(
            DiscountRuleType.MultiPurchase
          ),
          description: `Automatically applies a discount when the same attendee is booked into multiple sessions or activities, e.g. a ${getCurrencySymbol(
            client.currency
          )}5 discount when an attendee is booked for 3 or more sessions.`
        },
        {
          value: DiscountRuleType.MultiAttendee,
          label: getDiscountRuleTypeReadableName(
            DiscountRuleType.MultiAttendee
          ),
          description:
            "Automatically applies a discount when multiple attendees are booked, e.g. 10% off each additional attendee."
        },
        {
          value: DiscountRuleType.Code,
          label: getDiscountRuleTypeReadableName(DiscountRuleType.Code),
          description:
            "A discount is applied when a user enters a code during checkout."
        }
      ],
      isRequired: true,
      validate: [{ type: "required" }],
      isDisabled: shouldDisableConfigFields,
      formTemplate,
      initialValue: discountRule?.type || type,
      hideField: Boolean(type)
    },
    {
      index: 1,
      arrayField: false,
      component:
        formTemplate === FormTemplateType.Seamless
          ? "radio-group-with-description"
          : "radio-group-with-description-plain",
      name: `${prefixPath}multiPurchaseType`,
      label: "Multi purchase discount type",
      options: [
        {
          value: MultiPurchaseDiscountRuleType.Activity,
          label: "Activity",
          description:
            "Applies discount based on the number of activities booked for the same attendee."
        },
        {
          value: MultiPurchaseDiscountRuleType.Session,
          label: "Session",
          description:
            "Applies discount based on the number of activity sessions booked for the same attendee."
        }
      ],
      ...(formTemplate === FormTemplateType.Seamless && {
        showLabel: true
      }),
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: discountRule?.multiPurchaseType,
      isDisabled: shouldDisableConfigFields,
      condition: {
        when: `${prefixPath}type`,
        is: DiscountRuleType.MultiPurchase
      }
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefixPath}code`,
      label: "Code",
      isRequired: true,
      validate: [
        { type: validatorTypes.REQUIRED },
        ...(!hasExistingCodeThatDoesNotMatchValidation
          ? [
              {
                type: validatorTypes.PATTERN,
                pattern: /^[a-zA-Z0-9]*$/,
                message: "Code can only contain letters and numbers."
              }
            ]
          : [])
      ],
      formTemplate,
      initialValue: discountRule?.code,
      helpText:
        "The discount code users will enter at the checkout payment stage to apply the discount.",
      condition: {
        when: `${prefixPath}type`,
        is: DiscountRuleType.Code
      }
    },
    {
      index: 2,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: `${prefixPath}codeUsage`,
      label: "Discount code usage",
      options: [
        {
          value: DiscountCodeUsageType.AllActivities,
          label: "All activities",
          description:
            "Discount will be applied to all activities in a booking."
        },
        {
          value: DiscountCodeUsageType.SelectedActivities,
          label: "Selected activities",
          description:
            "Discount will be applied to items in a booking for selected activities."
        }
      ],
      isRequired: true,
      validate: [{ type: "required" }],
      isDisabled: shouldDisableConfigFields,
      formTemplate,
      hideField: Boolean(type),
      condition: {
        when: `${prefixPath}type`,
        is: DiscountRuleType.Code
      },
      initialValue: discountRule?.codeUsage
    },
    ...(includeActivityGroupSelect
      ? [
          {
            index: 9,
            arrayField: false,
            component: "discount-rule-activity-groups",
            name: `${prefixPath}activityGroups`,
            label: "Activities applied to",
            discountRuleType: DiscountRuleType.MultiPurchase,
            resolveProps: (props, field, formOptions) => {
              const values = prefix
                ? formOptions.getState().values[prefix]
                : formOptions.getState().values;
              const { type } = values;
              return {
                discountRuleType: type,
                helpText:
                  type === DiscountRuleType.Code
                    ? ""
                    : "Discount rules can also be assigned to activities when they are created or edited."
              };
            },
            id: discountRule?._id,
            activityGroups,
            venues,
            activityFields,
            client,
            formTemplate,
            initialValue: discountRule?.activityGroups || [],
            condition: {
              or: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiAttendee
                },
                {
                  and: [
                    {
                      when: `${prefixPath}type`,
                      is: DiscountRuleType.Code
                    },
                    {
                      when: `${prefixPath}codeUsage`,
                      is: DiscountCodeUsageType.SelectedActivities
                    }
                  ]
                }
              ]
            }
          }
        ]
      : []),
    {
      index: 3,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}minimumTotal`,
      min: 0,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      label: "Minimum total",
      resolveProps: (props, field, formOptions) => {
        const values = prefix
          ? formOptions.getState().values[prefix]
          : formOptions.getState().values;
        const { codeUsage } = values;
        return {
          discountRuleType: type,
          helpText:
            codeUsage === DiscountCodeUsageType.AllActivities
              ? "Discount will apply when booking total is at least this amount. Leave empty if you do not wish to specify a minimum total."
              : "Discount will apply when booking total for the selected activities is at least this amount. Leave empty if you do not wish to specify a minimum total."
        };
      },
      formTemplate,
      initialValue: discountRule?.minimumTotal
        ? getCurrencyAmountForDisplay(
            discountRule.minimumTotal,
            client.currency
          )
        : null,
      client,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.Code
          },
          {
            when: `${prefixPath}codeUsage`,
            isNotEmpty: true
          }
        ]
      }
    },
    {
      index: 3,
      arrayField: false,
      component:
        formTemplate === FormTemplateType.Seamless
          ? "radio-group-with-description"
          : "radio-group-with-description-plain",
      inputType: "number",
      name: `${prefixPath}sessionsOption`,
      label: "Sessions option",
      isRequired: true,
      isDisabled: shouldDisableConfigFields,
      validate: [{ type: "required" }],
      resolveProps: (props, field, formOptions) => {
        const values = prefix
          ? formOptions.getState().values[prefix]
          : formOptions.getState().values;
        const { type } = values;
        return {
          options:
            type === DiscountRuleType.MultiPurchase
              ? [
                  {
                    value: DiscountRuleSessionsOption.SameActivity,
                    label: "Sessions must be from the same activity",
                    description:
                      "The rule will only be applied when the sessions booked for the attendee are all within the same activity."
                  },
                  {
                    value: DiscountRuleSessionsOption.MultipleActivities,
                    label: "Sessions can be from multiple activities",
                    description:
                      "The rule will be applied when the sessions booked for the attendee are from any activities this rule is assigned to."
                  }
                ]
              : [
                  {
                    value: DiscountRuleSessionsOption.SameActivity,
                    label: "Sessions must be from the same activity",
                    description:
                      "The rule will only be applied when the attendees are booked into same activity."
                  },
                  {
                    value: DiscountRuleSessionsOption.MultipleActivities,
                    label: "Sessions can be from multiple activities",
                    description:
                      "The rule will be applied when the attendees are booked into any of the any activities this rule is assigned to."
                  }
                ]
        };
      },

      formTemplate,
      inModal,
      initialValue: discountRule?.sessionsOption,
      condition: {
        or: [
          {
            and: [
              {
                when: `${prefixPath}type`,
                is: DiscountRuleType.MultiPurchase
              },
              {
                when: `${prefixPath}multiPurchaseType`,
                is: MultiPurchaseDiscountRuleType.Session
              }
            ]
          },
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.MultiAttendee
          }
        ]
      }
    },
    {
      index: 4,
      arrayField: false,
      component: componentTypes.SELECT,
      name: `${prefixPath}unit`,
      label: "Unit",
      isRequired: true,
      disabled: shouldDisableConfigFields,
      validate: [{ type: "required" }],
      resolveProps: (props, field, formOptions) => {
        const values = prefix
          ? formOptions.getState().values[prefix]
          : formOptions.getState().values;
        const { type } = values;
        return {
          options: [
            ...(type === DiscountRuleType.Code
              ? [
                  {
                    value: DiscountRuleUnit.FixedAmount,
                    label: "Fixed amount"
                  },
                  {
                    value: DiscountRuleUnit.Percentage,
                    label: "Percentage"
                  }
                ]
              : []),
            ...(type === DiscountRuleType.MultiAttendee
              ? [
                  {
                    value: DiscountRuleUnit.AmountAdditionalAttendee,
                    label: "Amount on additional attendee"
                  },
                  {
                    value: DiscountRuleUnit.PercentageAdditionalAttendee,
                    label: "Percentage on additional attendee"
                  }
                ]
              : [])
            // for multi purchase discounts, we set the unit in the `conditions`
          ]
        };
      },
      formTemplate,
      initialValue: discountRule?.unit,
      condition: {
        or: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.MultiAttendee
          },
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.Code
          }
        ]
      }
    },
    {
      index: 5,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}fixedAmount`,
      label: "Fixed amount",
      isRequired: true,
      min: 0,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: discountRule?.fixedAmount
        ? getCurrencyAmountForDisplay(discountRule.fixedAmount, client.currency)
        : null,
      client,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.Code
          },
          {
            when: `${prefixPath}unit`,
            is: DiscountRuleUnit.FixedAmount
          }
        ]
      }
    },
    {
      index: 6,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}percentage`,
      label: "Percentage",
      suffix: "%",
      isRequired: true,
      min: 0,
      max: 100,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Percentage cannot be less than 0.";
          } else if (valueParsed > 100) {
            return "Percentage cannot be greater than 100.";
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: discountRule?.percentage,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.Code
          },
          {
            when: `${prefixPath}unit`,
            is: DiscountRuleUnit.Percentage
          }
        ]
      }
    },
    {
      index: 7,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}amountAdditionalAttendee`,
      label: "Amount per additional attendee",
      isRequired: true,
      min: 0,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      helpText:
        "This amount will be deducted for each additional attendee booked.",
      formTemplate,
      initialValue: discountRule?.amountAdditionalAttendee
        ? getCurrencyAmountForDisplay(
            discountRule.amountAdditionalAttendee,
            client.currency
          )
        : null,
      client,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.MultiAttendee
          },
          {
            when: `${prefixPath}unit`,
            is: DiscountRuleUnit.AmountAdditionalAttendee
          }
        ]
      }
    },
    {
      index: 8,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}percentageAdditionalAttendee`,
      label: "Percentage on sessions for additional attendees",
      suffix: "%",
      isRequired: true,
      min: 0,
      max: 100,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Percentage cannot be less than 0.";
          } else if (valueParsed > 100) {
            return "Percentage cannot be greater than 100.";
          }
          return undefined;
        }
      ],
      helpText:
        "This percentage discount will be applied to value of the sessions booked for additional attendees.",
      formTemplate,
      initialValue: discountRule?.percentageAdditionalAttendee,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.MultiAttendee
          },
          {
            when: `${prefixPath}unit`,
            is: DiscountRuleUnit.PercentageAdditionalAttendee
          }
        ]
      }
    },
    {
      index: 18,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}maximumDiscountAmount`,
      min: 0,
      step: "any",
      disabled: shouldDisableConfigFields,
      validate: [
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 1) {
            return "Amount cannot be less than 1.";
          }
          return undefined;
        }
      ],
      label: "Maximum discount amount",
      helpText:
        "The maximum discount that will be applied. Leave empty if you do not wish to specify a maximum discount amount.",
      formTemplate,
      initialValue: discountRule?.maximumDiscountAmount
        ? getCurrencyAmountForDisplay(
            discountRule.maximumDiscountAmount,
            client.currency
          )
        : null,
      client,
      condition: {
        and: [
          {
            when: `${prefixPath}type`,
            is: DiscountRuleType.Code
          },
          {
            when: `${prefixPath}unit`,
            is: DiscountRuleUnit.Percentage
          }
        ]
      }
    },
    ...(inModal
      ? [
          {
            index: 16,
            component: "field-array",
            name: `${prefixPath}multiSessionConditions`,
            label: "Conditions",
            itemLabel: "Condition",
            isRequired: true,
            validate: [
              {
                type: "required",
                message: "At least one condition should be added."
              }
            ],
            defaultItem: {},
            initialValue: [{}],
            fields: generateMultiSessionDiscountRuleConditionFormSchema({
              formTemplate: FormTemplateType.Seamless,
              prefix: "",
              inlineForm: true,
              client
            }).fields,
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Session
                }
              ]
            }
          },
          {
            index: 17,
            component: "field-array",
            name: `${prefixPath}multiActivityConditions`,
            label: "Conditions",
            itemLabel: "Condition",
            isRequired: true,
            validate: [
              {
                type: "required",
                message: "At least one condition should be added."
              }
            ],
            defaultItem: {},
            initialValue: [{}],
            fields: generateMultiActivityDiscountRuleConditionFormSchema({
              formTemplate: FormTemplateType.Seamless,
              prefix: "",
              inlineForm: true,
              client
            }).fields,
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Activity
                }
              ]
            }
          }
        ]
      : []),
    {
      index: 10,
      arrayField: false,
      component: "discount-rule-date-range",
      name: `${prefixPath}dates`,
      label: "Date range",
      formTemplate,
      inModal,
      resolveProps: (props, field, formOptions) => {
        const values = prefix
          ? formOptions.getState().values[prefix]
          : formOptions.getState().values;
        const { type } = values;
        return {
          discountRuleType: type
        };
      },
      ...(discountRule && {
        initialValue: getInitialDiscountRuleStartDateEndDate(
          discountRule,
          client
        )
      })
    },
    {
      index: 11,
      arrayField: false,
      component: "discount-rule-redemption-limits",
      name: `${prefixPath}redemptionLimit`,
      label: "Redemption limits",
      formTemplate,
      inModal,
      condition: {
        when: `${prefixPath}type`,
        is: DiscountRuleType.Code
      },
      ...(discountRule && {
        initialValue: getInitialDiscountRedemptionLimitValue(discountRule)
      })
    },
    {
      index: 14,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: `${prefixPath}enabled`,
      label: "Enabled",
      dataType: dataTypes.BOOLEAN,
      inModal,
      formTemplate,
      initialValue: discountRule ? discountRule.enabled : true
    },
    ...(!inModal
      ? [
          {
            index: 15,
            component: "section-header",
            name: `${prefixPath}multiSessionDiscountRuleItemsHeader`,
            title: "Conditions",
            description:
              "You can create conditions based on the number of sessions booked. Where multiple conditions are matched, the condition for the greatest number of sessions booked will be applied.",
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Session
                }
              ]
            }
          },
          {
            index: 16,
            component: "multi-session-discount-rule-conditions",
            name: `${prefixPath}multiSessionConditions`,
            inModal,
            formTemplate,
            label: "Items",
            initialValue: discountRule?.multiSessionConditions,
            isNewDiscountRule: !discountRule,
            disabled: shouldDisableConfigFields,
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Session
                }
              ]
            }
          },
          {
            index: 15,
            component: "section-header",
            name: `${prefixPath}multiActivityDiscountRuleItemsHeader`,
            title: "Conditions",
            description:
              "You can create conditions based on the number of activities booked. Where multiple conditions are matched, the condition for the greatest number of activities booked will be applied.",
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Activity
                }
              ]
            }
          },
          {
            index: 16,
            component: "multi-activity-discount-rule-conditions",
            name: `${prefixPath}multiActivityConditions`,
            inModal,
            formTemplate,
            label: "Items",
            initialValue: discountRule?.multiActivityConditions,
            isNewDiscountRule: !discountRule,
            disabled: shouldDisableConfigFields,
            condition: {
              and: [
                {
                  when: `${prefixPath}type`,
                  is: DiscountRuleType.MultiPurchase
                },
                {
                  when: `${prefixPath}multiPurchaseType`,
                  is: MultiPurchaseDiscountRuleType.Activity
                }
              ]
            }
          }
        ]
      : [])
  ];

  const schema = {
    fields
  };

  return schema;
};

interface GenerateMultiSessionDiscountRuleItemFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix?: string;
  editingItem?: MultiSessionCondition;
  inlineForm?: boolean;
  existingMinimumSessionValues?: number[];
  existingUnitValue?: DiscountRuleUnit;
  client: Client;
}

export const generateMultiSessionDiscountRuleConditionFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix = "",
  editingItem,
  inlineForm = false,
  existingMinimumSessionValues = [],
  existingUnitValue,
  client
}: GenerateMultiSessionDiscountRuleItemFormSchemaData) => {
  const prefixPath = prefix ? `${prefix}.` : "";

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}minimumSessions`,
      label: "Minimum sessions",
      isRequired: true,
      min: 2,
      validate: [
        {
          type: "required"
        },
        value => {
          const valueParsed = parseInt(value, 10);
          if (existingMinimumSessionValues.includes(valueParsed)) {
            return `A condition has already been created for a minimum of ${valueParsed} sessions.`;
          } else if (valueParsed < 2) {
            return "Minimum sessions must be at least 2.";
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: editingItem?.minimumSessions,
      ...(!inlineForm && {
        helpText:
          "The minimum number of sessions needed to be booked for the discount to apply."
      })
    },
    {
      index: !inlineForm ? 1 : 0,
      arrayField: false,
      component: componentTypes.SELECT,
      name: `${prefixPath}unit`,
      label: "Unit",
      isRequired: true,
      disabled: Boolean(existingUnitValue),
      validate: [{ type: "required" }],
      options: [
        {
          value: DiscountRuleUnit.FixedAmount,
          label: "Fixed amount"
        },
        {
          value: DiscountRuleUnit.Percentage,
          label: "Percentage"
        },
        {
          value: DiscountRuleUnit.AmountPerSession,
          label: "Amount per session"
        }
      ],
      formTemplate,
      initialValue: editingItem?.unit ?? existingUnitValue
    },
    {
      index: !inlineForm ? 2 : 0, // slight have to reduce spacing on inline form
      arrayField: false,
      component: "currency",
      name: `${prefixPath}fixedAmount`,
      label: "Fixed amount",
      isRequired: true,
      min: 0,
      step: "any",
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      condition: {
        when: field => getUnitFieldName(field, "fixedAmount"),
        is: DiscountRuleUnit.FixedAmount
      },
      formTemplate,
      initialValue: editingItem?.fixedAmount
        ? getCurrencyAmountForDisplay(editingItem.fixedAmount, client.currency)
        : null,
      client
    },
    {
      index: !inlineForm ? 3 : 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}percentage`,
      label: "Percentage",
      suffix: "%",
      min: 0,
      max: 100,
      step: "any",
      isRequired: true,
      validate: [
        {
          type: "required"
        },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Percentage cannot be less than 0.";
          } else if (valueParsed > 100) {
            return "Percentage cannot be greater than 100.";
          }
          return undefined;
        }
      ],
      condition: {
        when: field => getUnitFieldName(field, "percentage"),
        is: DiscountRuleUnit.Percentage
      },
      formTemplate,
      initialValue: editingItem?.percentage
    },
    {
      index: !inlineForm ? 4 : 0,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}amountPerSession`,
      label: "Amount per session",
      isRequired: true,
      min: 0,
      step: "any",
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      condition: {
        when: field => getUnitFieldName(field, "amountPerSession"),
        is: DiscountRuleUnit.AmountPerSession
      },
      formTemplate,
      initialValue: editingItem?.amountPerSession
        ? getCurrencyAmountForDisplay(
            editingItem.amountPerSession,
            client.currency
          )
        : null,
      client
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

interface GenerateMultiActivityDiscountRuleItemFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix?: string;
  editingItem?: MultiActivityCondition;
  inlineForm?: boolean;
  existingMinimumActivityValues?: number[];
  existingUnitValue?: DiscountRuleUnit;
  client: Client;
}

export const generateMultiActivityDiscountRuleConditionFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix = "",
  editingItem,
  inlineForm = false,
  existingMinimumActivityValues = [],
  existingUnitValue,
  client
}: GenerateMultiActivityDiscountRuleItemFormSchemaData) => {
  const prefixPath = prefix ? `${prefix}.` : "";

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}minimumActivities`,
      label: "Minimum activities",
      isRequired: true,
      min: 2,
      validate: [
        {
          type: "required"
        },
        value => {
          const valueParsed = parseInt(value, 10);
          if (existingMinimumActivityValues.includes(valueParsed)) {
            return `A condition has already been created for a minimum of ${valueParsed} activities.`;
          } else if (valueParsed < 2) {
            return "Minimum activities must be at least 2.";
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: editingItem?.minimumActivities,
      ...(!inlineForm && {
        helpText:
          "The minimum number of activities needed to be booked for the discount to apply."
      })
    },
    {
      index: !inlineForm ? 1 : 0,
      arrayField: false,
      component: componentTypes.SELECT,
      name: `${prefixPath}unit`,
      label: "Unit",
      isRequired: true,
      disabled: Boolean(existingUnitValue),
      validate: [{ type: "required" }],
      options: [
        {
          value: DiscountRuleUnit.FixedAmount,
          label: "Fixed amount"
        },
        {
          value: DiscountRuleUnit.Percentage,
          label: "Percentage"
        }
      ],
      formTemplate,
      initialValue: editingItem?.unit ?? existingUnitValue
    },
    {
      index: !inlineForm ? 2 : 0, // slight have to reduce spacing on inline form
      arrayField: false,
      component: "currency",
      name: `${prefixPath}fixedAmount`,
      label: "Fixed amount",
      isRequired: true,
      min: 0,
      step: "any",
      validate: [
        { type: "required" },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      condition: {
        when: field => getUnitFieldName(field, "fixedAmount"),
        is: DiscountRuleUnit.FixedAmount
      },
      formTemplate,
      initialValue: editingItem?.fixedAmount
        ? getCurrencyAmountForDisplay(editingItem.fixedAmount, client.currency)
        : null,
      client
    },
    {
      index: !inlineForm ? 3 : 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}percentage`,
      label: "Percentage",
      suffix: "%",
      min: 0,
      max: 100,
      step: "any",
      isRequired: true,
      validate: [
        {
          type: "required"
        },
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Percentage cannot be less than 0.";
          } else if (valueParsed > 100) {
            return "Percentage cannot be greater than 100.";
          }
          return undefined;
        }
      ],
      condition: {
        when: field => getUnitFieldName(field, "percentage"),
        is: DiscountRuleUnit.Percentage
      },
      formTemplate,
      initialValue: editingItem?.percentage
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export const getInitialDiscountRuleStartDateEndDate = (
  discountRule: DiscountRule,
  client: Client
): StartEndDateValue => {
  return {
    shouldLimitDatePeriod:
      Boolean(discountRule?.startDate) || Boolean(discountRule?.endDate),
    specifyStartDate: Boolean(discountRule?.startDate),
    specifyEndDate: Boolean(discountRule?.endDate),
    startDate: discountRule?.startDate
      ? formatDate(discountRule.startDate, "yyyy-MM-dd", client.timeZone)
      : "",
    endDate: discountRule?.endDate
      ? formatDate(discountRule.endDate, "yyyy-MM-dd", client.timeZone)
      : ""
  };
};

export const getInitialDiscountRedemptionLimitValue = (
  discountRule: DiscountRule
): DiscountRuleRedemptionLimitsValue => {
  return {
    shouldLimitUserRedemptions: Boolean(discountRule?.maxUserRedemptions),
    maxUserRedemptions: discountRule?.maxUserRedemptions
      ? String(discountRule?.maxUserRedemptions)
      : "",
    shouldLimitRedemptions: Boolean(discountRule?.maxRedemptions),
    maxRedemptions: discountRule?.maxRedemptions
      ? String(discountRule?.maxRedemptions)
      : ""
  };
};

export const validateDiscountRuleForm = (
  values: DiscountRuleFormData
): FormErrors => {
  const errors: FormErrors = {};

  const hasStartDate =
    values.dates?.specifyStartDate && values.dates?.startDate;
  const hasEndDate = values.dates?.specifyEndDate && values.dates?.endDate;

  if (hasStartDate && hasEndDate) {
    const isEndBeforeStart = isBefore(
      new Date(values.dates.endDate),
      new Date(values.dates.startDate)
    );
    if (isEndBeforeStart) {
      errors.dates = "End date cannot be before start date.";
    }
  }
  return errors;
};
