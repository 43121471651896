import type { Activity } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { User } from "types/model/user";

export enum WaitlistEntryType {
  SingleSession = "singleSession",
  AllSessions = "allSessions"
}

export enum WaitlistEntryStatus {
  Waiting = "waiting",
  Ready = "ready",
  Used = "used",
  Expired = "expired",
  Cancelled = "cancelled"
}

export type WaitlistEntry = {
  _id: string;
  created: Date;
  updated: Date;
  activityGroup: ActivityGroup;
  type: WaitlistEntryType;
  user: User;
  attendee: Attendee;
  activity: Activity;
  position: number;
  status: WaitlistEntryStatus;
  usedAt: Date;
  expiresAt: Date;
  cancelledAt: Date;
  client: Client;
};

export type IWaitlistEntry = Omit<
  WaitlistEntry,
  "_id" | "save" | "deleteOne" | "toObject"
>;
