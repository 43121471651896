import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer";
import {
  FormSpy,
  useFieldApi,
  useFormApi
} from "@data-driven-forms/react-form-renderer";
import { Alert } from "components/Alert";
import { AttendeeAgeWarning } from "features/attendees/components/AttendeeAgeWarning";
import { getAttendeeFullName } from "helpers/helpers";
import Link from "next/link";
import type { Activity, Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import { WaitlistType } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { WaitlistEntry } from "types/model/waitlistEntry";
import { WaitlistEntryType } from "types/model/waitlistEntry";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<unknown, HTMLElement> & {
  attendees?: Attendee[];
  sessions?: {
    ticket: Ticket<SubscriptionPlan>;
    activities: Activity<string>[];
  }[];
  activityGroup?: ActivityGroup;
  userWaitlistEntries?: WaitlistEntry[];
};

/**
 * Used to select an attendee from a list of attendees. If there aren't any
 * attendees, the component will display a message to add attendees in the
 * My Account area. Used in the join waitlist form modal. This component is
 * styled uniquely to fit the modal.
 */
export const WaitlistAttendeeOptions = (props: UseFieldApiConfig) => {
  const {
    label,
    required,
    meta: { error, touched },
    input,
    attendees = [],
    sessions,
    activityGroup,
    userWaitlistEntries,
    currentAttendee
  }: Props = useFieldApi(props);

  const data = sessions[0];
  const form = useFormApi();

  const waitlistType = activityGroup.waitlistType;
  const joinedAllSessionsWaitlist =
    waitlistType === WaitlistType.AllSessions &&
    !!userWaitlistEntries.find(
      entry =>
        entry.attendee?._id === input.value &&
        entry.type === WaitlistEntryType.AllSessions
    );

  const options = attendees.map(attendee => {
    return {
      value: attendee._id,
      label: getAttendeeFullName(attendee)
    };
  });

  const noOptions = !options.length;
  const hasError = touched && error;

  return (
    <div className="flex flex-col gap-1">
      <label
        className="block text-sm font-medium leading-5 text-gray-700"
        htmlFor={input.name}
      >
        {label}
        {required && "*"}
      </label>
      <div className="flex flex-col gap-1">
        <select
          className={cn(
            "mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 disabled:opacity-50 sm:text-sm sm:leading-6",
            hasError && "ring-red-500"
          )}
          onChange={({ target: { value } }) => {
            input.onChange(value);

            // Reset the selected sessions if the attendee changes.
            form.change("activities", []);
          }}
          disabled={noOptions}
          name={input.name}
          id={input.name}
          value={input.value as string}
        >
          <option value="">Select attendee</option>
          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        {joinedAllSessionsWaitlist && (
          <Alert className="mt-1">
            The attendee has already joined the waitlist for this activity.
          </Alert>
        )}
        {hasError && <p className="text-sm text-red-600">{error}</p>}
        {activityGroup.waitlistType === WaitlistType.AllSessions && (
          <AttendeeAgeWarning
            userAttendees={attendees}
            currentAttendee={currentAttendee}
            activityGroup={activityGroup}
            sessions={data.activities}
          />
        )}
        {noOptions && (
          <p className="text-sm text-gray-500">
            You currently have no attendees. Please add them first in the{" "}
            <Link href="/account/attendees">
              <a className="font-medium text-indigo-600">My Account</a>
            </Link>{" "}
            area.
          </p>
        )}
      </div>
    </div>
  );
};

/**
 * Used to select an attendee from a list of attendees. If there aren't any
 * attendees, the component will display a message to add attendees in the
 * My Account area. Used in the join waitlist form modal. This component is
 * styled uniquely to fit the modal.
 */
export const SelectWaitlistAttendee = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <WaitlistAttendeeOptions
          {...fieldProps}
          currentAttendee={values.attendee}
          name={fieldProps.input.name}
        />
      )}
    </FormSpy>
  );
};
